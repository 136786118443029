export const PracticeId = {
  CARBON: "5bdaef44-8ff0-439f-99d7-3285afcc6911",
  NORTHBAY: "a6eca285-32b5-40d9-a465-ffda0ea71afa",
  ST_MARYS: "036baaae-b295-474d-9e25-e03e532eb58e",
  BERKELEY_VAX: "136687ba-9e45-4c61-9606-f26399405163",
} as const;

/**
 * @deprecated This was made for a hotfix and should generally not be used.
 */
export const PracticeNameById: Record<string, string> = {
  [PracticeId.CARBON]: "Carbon Health",
  [PracticeId.NORTHBAY]: "Northbay Urgent Care",
  [PracticeId.ST_MARYS]: "Saint Mary's",
};

export const practiceIds = Object.values(PracticeId);

export type PracticeId = typeof PracticeId[keyof typeof PracticeId];
