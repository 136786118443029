export enum OsType {
  Android = "Android",
  IOS = "IOS",
  Other = "Other",
}

export enum EventCategory {
  Custom = "Custom",
  Impression = "Impression",
  Error = "Error",
  Lifecycle = "Lifecycle",
  Location = "Location",
  Network = "Network",
  Performance = "Performance",
  Tap = "Tap",
}

/**
 * Does not include types that are irrelevant to `carbon-website-next`
 */
export enum CarbonUserType {
  Unknown = "Unknown",
}

/**
 * Does not include types that are irrelevant to `carbon-website-next`
 */
export enum AppType {
  PublicWebsite = "PublicWebsite",
}

/**
 * Randomly generated UUID map.
 *
 * DO NOT CHANGE THESE once defined -- it will cause problems for data science upstream.
 * In the future we should codegen this from https://github.com/mdcollab/event-dictionary
 */
export enum EventType {
  ApptConfirmationStepViewed = "e4fd13d1-3f91-4573-b9cc-0b802fd4dd13",
  CancelApptClicked = "ad3ea575-a881-41ee-bea8-68a7dd127bd7",
  CareDiscoveryAppointmentReasonSearched = "61bea377-8969-41d4-9617-1a010e4056bd",
  CareDiscoveryViewLocationsClicked = "be646ead-1f57-4d20-a475-dde967c2424d",
  ClinicOptionsViewed = "cd74950f-c892-3167-8a7e-53565adca7fc",
  CompleteBookingClicked = "b48478d4-f28a-4287-8b7c-ac7e233b9ede",
  DateofBirthTyped = "af4e6e85-0cb0-4451-a63e-5a51dbbf8002",
  DirectToPatientAppStarted = "c5125de2-e45d-4aa9-abb0-14ba86a1dc54",
  EmailCheckClicked = "d42ea859-87cd-40a7-a28a-21c55ace3c8f",
  EmailTyped = "dd541df9-ecd3-48eb-b687-ac731296d479",
  FirstNameTyped = "daed96bc-7896-4318-b110-b31d044a0e1f",
  LastNameTyped = "3604712d-d2fe-4935-ace6-be707a41148e",
  PhoneNumberTyped = "df9a0960-88c7-46b9-bd3e-87cf1839ac69",
  QuitFlowStepViewed = "3ec96e81-7808-43fa-aa84-7fc15bf5b1e2",
  QuitFlowClicked = "346f3039-4f55-4aa1-8765-edd534260b75",
  RescheduleApptClicked = "49d3658c-27f8-4d78-a734-9a2ce8539f55",
  TypedEmailError = "1cd4cc8f-54d9-48bf-b399-5ea093b46adf",
  WebsitePageChanged = "2df97636-c850-32c4-8237-fe2341e65fdc",
  ProfileCreationStepViewed = "d0f5b881-45f8-4378-bb7f-31ebf53cb888",
  AccountStepViewed = "aa252125-e9b9-4749-a15d-c7e799a7f97f",
  ChangeDateClicked = "5d2b35d3-d2bc-4125-b310-fd0a5130c364",
  SlotClicked = "60c1c2ca-7c18-3dd1-9008-7d648ca57767",
  TimeStepViewed = "bff368fd-ffec-4878-bbd1-6b31b86e2712",
  SlotOptionsViewed = "ea023194-b152-3cc5-87d0-a3503cf9d9a6",
  TimeReselectionStepViewed = "6401c0f3-11be-46b9-b1a9-3b226440bfd1",
  AppointmentBookingStarted = "03dc1af6-4bb2-4129-a94e-5e00d6da1c38",
  AppointmentSchedulingConfirmed = "1a36c18c-4e50-39c7-ad97-441dc6f1c011",
  AppointmentReasonOptionsViewed = "bd312bf4-aa11-48c4-a0fd-f508b0640ffd",
  AppointmentBookingHomeWidgetClicked = "1032a33c-5535-3f9f-997a-5970e925c882",
  AppointmentBookingReasonWidgetClicked = "bfcf1e7c-9b71-3119-9126-c7564f103772",
  AppointmentBookingWebsiteWidgetClicked = "3b050897-bfa6-3499-9e7b-558a64df4798",
  SearchedAppointmentReasonClicked = "8d75ac88-5896-39ab-b635-bf36f9d5ef16",
  AppointmentReasonClicked = "6a419255-9a01-3af5-8ad5-7f27f5952d02",
}

export enum CustomCategory {
  AppointmentScheduling = "AppointmentScheduling",
  CareDiscovery = "CareDiscovery",
}

export enum Label {
  AppointmentReason = "AppointmentReason",
  AppointmentReasonOptions = "ApptReasonOptions",
  SearchedAppointmentReason = "SearchedAppointmentReason",
  AppointmentBookingWebsiteWidget = "AppointmentBooking - WebsiteWidget",
  AppointmentBookingReasonWidget = "AppointmentBooking - ReasonWidget",
  AppointmentBookingHomeWidget = "AppointmentBooking - HomeWidget",
  ViewLocations = "ViewLocations",
}

export enum Action {
  Clicked = "Clicked",
  Searched = "Searched",
}

export const Validator = {
  OsType: (osType: string): osType is OsType => typeof osType === "string" && osType in OsType,
} as const;
